import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";

import OrderReceive from "./components/OrderReceive";
import OrderReceiveDialog from "./components/OrderReceiveDialog";
import OrderSendDialog from "./components/OrderSendDialog";

import OrderSend from "./components/OrderSend";

import { useLocation, useNavigate } from "react-router-dom";

// Components
import EmptyList from "components/Common/EmtyList";

// import OrderSend from "./components/OrderSend";

// Api
import apiOrderGetAll from "./api/getAll";

// Socket
import socket from "socket";

function OrdersPage() {
  let [orders, setOrders] = useState([]);
  let [orderReceiveData, setOrderReceiveData] = useState(null);
  let [showOrderReceiveDialog, setShowOrderReceiveDialog] = useState(false);
  let [orderSendData, setOrderSendData] = useState(null);
  let [showOrderSendDialog, setShowOrderSendDialog] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  let orderSendInfo = location?.state?.orderSendData;

  console.log(
    "orderSendData",
    orderSendData,
    "showOrderSendDialog",
    showOrderSendDialog
  );

  useEffect(() => {
    apiOrderGetAll().then((res) => {
      setOrders(res.data);
      setLoaded(true);
    });

    socket.on("order_send_add", (data) => {
      setOrders((prev) => [data, ...prev]);
    });
    socket.on("order_receive_add", (data) => {
      setOrders((prev) => [data, ...prev]);
    });
    // merchant_order-receive_add
    socket.on("order_send_upd", (data) => {
      // setOrderSendData((prev) => (prev?._id == data._id ? data : prev));
      setOrders((prev) => prev.map((d) => (d._id == data._id ? data : d)));
    });
    socket.on("order_receive_upd", (data) => {
      setOrderReceiveData((prev) => (prev?._id == data._id ? data : prev));
      setOrders((prev) => prev.map((d) => (d._id == data._id ? data : d)));
    });

    return () => {
      socket.off("order_send_add");
      socket.off("order_receive_add");
      socket.off("order_send_upd");
      socket.off("order_receive_upd");
    };
  }, []);

  useEffect(() => {
    if (orderSendInfo && !showOrderSendDialog) {
      openOrderSendDialog(orderSendInfo);
      navigate(".", { state: {} });
    }
  }, [orderSendInfo]);

  // Handlers
  const openOrderReceiveDialog = (data) => {
    setOrderReceiveData(data);
    setShowOrderReceiveDialog(true);
  };

  const openOrderSendDialog = (data) => {
    setOrderSendData(data);
    setShowOrderSendDialog(true);
  };

  return (
    <Box>
      {!!(loaded && !orders.length) && (
        <EmptyList
          style={{
            mt: 4,
          }}
          name="Ни одной заявки еще не было создано"
        />
      )}

      <OrderReceiveDialog
        show={showOrderReceiveDialog}
        data={orderReceiveData}
        handleClose={() => {
          setShowOrderReceiveDialog(false);
          setOrderReceiveData(null);
        }}
      />
      <OrderSendDialog
        show={showOrderSendDialog}
        data={orderSendData}
        handleClose={() => {
          setShowOrderSendDialog(false);
          setOrderSendData(null);
        }}
      />
      <Grid container spacing={2}>
        {orders.map((o) => {
          return (
            <Grid item xs={12} key={o._id} md={6} lg={4}>
              <Order
                openOrderSendDialog={openOrderSendDialog}
                openOrderReceiveDialog={openOrderReceiveDialog}
                data={o}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function Order({ data, openOrderReceiveDialog, openOrderSendDialog }) {
  if (data.isReceive) {
    return (
      <OrderReceive
        clickHandler={() => {
          openOrderReceiveDialog(data);
        }}
        data={data}
      />
    );
  } else if (data.isSend) {
    return (
      <OrderSend
        clickHandler={() => {
          openOrderSendDialog(data);
        }}
        data={data}
      />
    );
  }
}

export { OrdersPage };
