import API from "./config/API";
import io from "socket.io-client";

let socket = io(API.SERVER, {
  transports: ["polling", "websocket", "flashsocket"],
  reconnection: true,
  pingInterval: 1000,
  pingTimeout: 1500,
});

socket.on("connect", function () {
  console.debug("Socket подключен!");
  auth();
});

socket.on("disconnect", function (e) {
  console.debug("Socket отключен!");
});
socket.on("connect_error", (error) => {
  console.debug("Ошибка подключения к сокету:", error);
});
socket.on("reconnect", function () {
  console.debug("Socket переподключение!");
});

function auth() {
  const token = localStorage.getItem("access_token");
  if (token) {
    socket.emit("auth", token);
  }
}

function logout() {
  //   const token = localStorage.getItem("access_token");
  //   if (token) {
  //     socket.emit("auth_leave", token);
  //   }
}

export { auth, socket, logout };

export default socket;
