import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from "@mui/material";

import CustomChip from "./CustomChip";
import moment from "moment";
import "moment/locale/ru";
import { getFullLinkIcon } from "utils/link";

import { Icon } from "@iconify/react";

function OrderReceive({ data, clickHandler }) {
  // bank
  // date
  // sum
  // Calc
  let status = data.status;
  let currency = data.currency;

  return (
    <Card>
      <CardActionArea onClick={clickHandler}>
        <CardContent>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "center",
              "& svg": {
                color: (t) => t.palette.primary.main,
              },
            }}
          >
            <Icon fontSize="22px" icon="uil:money-withdraw" />
            <Typography sx={{ ml: 1 }} variant="body1">
              Получение
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography color="text.hint" variant="body2">
              Статус:
            </Typography>
            <CustomChip action="receive" size="small" status={status} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography color="text.hint" variant="body2">
              Валюта:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: "20px", height: "20px" }}
                src={getFullLinkIcon(data?.currency?.logo)}
                alt="Валюта"
              />
              <Typography sx={{ ml: 1 }} fontWeight="600" variant="body2">
                {data?.currency?.name}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
            <Typography color="text.hint" variant="body2">
              Cумма:
            </Typography>
            <Typography fontWeight="600" variant="body2">
              {data.sum} RUB
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography color="text.hint" variant="body2">
              Создано:
            </Typography>
            <Typography fontWeight="600" variant="body2">
              {moment(data.createdAt).format("HH:mm, DD MMMM YYYY")}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default OrderReceive;
