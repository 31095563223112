import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { CircularProgress, Box } from "@mui/material";

// Pages

import { RegistrationPage } from "pages/RegistrationPage";
import { LoginPage } from "pages/LoginPage";
import { RecoverEmailPage } from "pages/RecoverEmailPage";
import MainPage from "pages/Main";
// Home
import { AgreementPage } from "pages/AgreemetPage";
import TariffPage from "pages/TariffPage";
import FaqPage from "pages/FaqPage";
import PanelPage from "pages/Panel/index";

//Cabinet
import { PersonalPage } from "pages/PersonalPage";
// import { SecurityPage } from "pages/SecurityPage";
import { RequisitesPage } from "pages/RequisitesPage";
import { OrdersPage } from "pages/OrdersPage";
import { TransfersPage } from "pages/TransfersPage";
import { HistoryPage } from "pages/History";
// import { NotificationPage } from "pages/NotificationPage";

// Layots
import HomeLayout from "components/Layouts/HomeLayout";
import ProtectedLayout from "components/Layouts/ProtectedLayout/index";
import AccountLayout from "components/Layouts/AccountLayout";

import { useAuth } from "hooks/auth/useAuth";
import { useAuction } from "context/AuctionContext";

export default function Router() {
  let { isAuth, loading } = useAuth();
  let { fetchData } = useAuction();

  useEffect(() => {
    if (!isAuth) return;
    fetchData(true);
  }, [isAuth]);

  function publicRoutes() {
    return (
      <>
        <Route element={<HomeLayout />}>
          <Route path="/agreement" element={<AgreementPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/tariff" element={<TariffPage />} />
          <Route path="/" element={<MainPage />} />
        </Route>
      </>
    );
  }

  function AuthRoutes() {
    return (
      <Routes>
        {publicRoutes()}
        <Route path="/panel" element={<ProtectedLayout />}>
          <Route path="dashboard" element={<PanelPage />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="history" element={<HistoryPage />} />
          <Route path="requisites" element={<RequisitesPage />} />
          <Route path="transfers" element={<TransfersPage />} />
          <Route path="account" element={<PersonalPage />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to="/panel/orders" replace={true} />}
        />
      </Routes>
    );
  }

  function UnauthRoutes() {
    return (
      <Routes>
        {publicRoutes()}
        <Route element={<HomeLayout />}>
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/recover-email" element={<RecoverEmailPage />} />
          <Route path="*" element={<Navigate to="/login" replace={true} />} />
        </Route>
      </Routes>
    );
  }

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 5,
        }}
      >
        <CircularProgress />
      </Box>
    );

  return isAuth ? <AuthRoutes /> : <UnauthRoutes />;
}
