import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Grid, Card, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";

import axios from "axios";

// Images
import walletPng from "../../assets/wallet.png";
import coinPng from "../../assets/gold.png";

import examp1 from "../../assets/examp1.png";
import examp2 from "../../assets/examp2.png";
import examp3 from "../../assets/examp3.png";


const MainPage = () => {
  return (
    <div>
      <Sector1 />
      <Sector2 />
      <Sector3 />
      <Sector4 />
      <Sector5 />
    </div>
  );
};

export default MainPage;

function Sector1() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        my: 3,
      }}
    >
      <Container maxWidth="lg">
        <Card
          sx={{
            height: "100%",
            borderRadius: "15px",
            padding: "64px 32px",
          }}
        >
          <Grid container spacing="4" alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography fontWeight="bold" variant="h4">
                Платформа для мерчантов, учавствующих в P2P сделках
              </Typography>
              <Typography sx={{ mt: 2 }} color="secondary" variant="body2">
                Пополняйте внутренний кошелек USDT, и зарабатывайте на
                банковских реквизитах и платежных системах, обрабатывая платежи.
              </Typography>
              <Button
                sx={{ mt: 3, px: 7 }}
                onClick={() => {
                  navigate("/register");
                }}
                type="submit"
                variant="contained"
              >
                Перейти к регистрации
              </Button>
            </Grid>
            <Grid sx={{ height: "100%" }} item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "column",
                  alignItems: "center",
                  py: 3,
                  //   position: "relative",
                }}
              >
                <img
                  style={{
                    width: "300px",
                    // top: "-320px",
                  }}
                  src={walletPng}
                />
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Box>
  );
}
function Sector2() {
  const [tariffs, setTariffs] = useState([]);

  useEffect(() => {
    axios.get("/currency").then(({ data }) => {
      setTariffs(data);
    });
  }, []);

  const minPercent = Math.min(
    ...tariffs.map((t) => {
      return t.receivePercent > t.sendPercent
        ? t.sendPercent
        : t.receivePercent;
    })
  );

  const maxPercent = Math.max(
    ...tariffs.map((t) => {
      return t.receivePercent < t.sendPercent
        ? t.sendPercent
        : t.receivePercent;
    })
  );

  return (
    <Box
      sx={{
        bgcolor: (t) => t.palette.background.paper,
        padding: "64px 32px",
      }}
    >
      <Container maxWidth="lg">
        <Typography textAlign="center" variant="h4">
          Получайте от{" "}
          <span
            style={{
              color: "#F4754F",
              fontWeight: "bold",
            }}
          >
            {" "}
            {minPercent}%{" "}
          </span>{" "}
          до{" "}
          <span
            style={{
              color: "#F4754F",
              fontWeight: "bold",
            }}
          >
            {" "}
            {maxPercent}%
          </span>{" "}
          <br /> за выполнение операций <br /> с различными платежными системами
        </Typography>
      </Container>
    </Box>
  );
}

function Sector3() {
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            padding: "64px 32px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography fontWeight="bold" variant="h5">
                  Работая с нами вы получите:
                </Typography>

                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ mb: 3 }} fontWeight="bold" variant="body1">
                    - Большое количество заявок ✅
                  </Typography>
                  <Typography sx={{ mb: 3 }} fontWeight="bold" variant="body1">
                    - Высокий процент прибыли с каждой операции 💰
                  </Typography>
                  <Typography sx={{ mb: 3 }} fontWeight="bold" variant="body1">
                    - Техническая поддержка 24/7 📱
                  </Typography>
                  <Typography sx={{ mb: 3 }} fontWeight="bold" variant="body1">
                    - Надежность и безопасность 🔒
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid sx={{ height: "100%" }} item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 3,
                  //   position: "relative",
                }}
              >
                <img
                  style={{
                    width: "300px",
                    // top: "-320px",
                  }}
                  src={coinPng}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

function Sector4() {
  return (
    <Box
      sx={{
        bgcolor: (t) => t.palette.background.paper,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            padding: "64px 32px",
          }}
        >
          <Typography fontWeight="bold" textAlign="center" variant="h5">
            Выбирайте удобный для вас способ работы
          </Typography>
          <Typography sx={{ mt: 3 }} textAlign="center" variant="body2">
            Обрабатывайте приходящие заявки, как через{" "}
            <span
              style={{
                color: "#F4754F",
                fontWeight: "bold",
              }}
            >
              Личный Кабинет
            </span>{" "}
            ,так и через{" "}
            <span
              style={{
                color: "#F4754F",
                fontWeight: "bold",
              }}
            >
              Телеграм Бота
            </span>
          </Typography>

          <Grid sx={{ mt: 2 }} container justifyContent="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "2px solid #f4754f",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src={examp1}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "2px solid #f4754f",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src={examp2}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "2px solid #f4754f",
                  overflow: "hidden",
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src={examp3}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

function Sector5() {
  const navigate = useNavigate();

  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            padding: "64px 32px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Начните{" "}
            <span
              style={{
                color: "#F4754F",
                fontWeight: "bold",
              }}
            >
              {" "}
              использовать свои активы, и зарабатывать{" "}
            </span>{" "}
            уже сейчас.
          </Typography>
          <Button
            sx={{ mt: 3, px: 7 }}
            onClick={() => {
              navigate("/register");
            }}
            variant="contained"
          >
            Перейти к регистрации
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
